.image-processor-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 20px;
  background-color: #f5f5f5;
}

.image-processor-card {
  width: 100%;
  max-width: 500px;
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.input-group {
  margin-bottom: 20px;
}

.input-group input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 16px;
  box-sizing: border-box;
}

.input-group input:focus {
  outline: none;
  border-color: #4a90e2;
}

.site-input {
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ddd;
  font-size: 16px;
  box-sizing: border-box;
}

.site-input:focus {
  outline: none;
  border-color: #4a90e2;
}

.upload-area {
  margin-bottom: 20px;
}

.upload-label {
  display: block;
  padding: 20px;
  border: 2px dashed #ddd;
  border-radius: 4px;
  cursor: pointer;
  text-align: center;
}

.upload-label:hover {
  border-color: #4a90e2;
}

.upload-text {
  font-size: 16px;
  margin-bottom: 8px;
}

.upload-subtext {
  font-size: 14px;
  color: #666;
}

.file-input {
  display: none;
}

.file-list {
  margin-bottom: 20px;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 4px;
}

.file-list-header {
  margin: 0 0 10px 0;
  font-size: 16px;
  font-weight: bold;
}

.file-list-content {
  max-height: 150px;
  overflow-y: auto;
}

.file-name {
  font-size: 14px;
  color: #666;
  margin-bottom: 5px;
}

.error-message {
  background-color: #fff2f0;
  color: #ff4d4f;
  padding: 10px;
  border-radius: 4px;
  margin-bottom: 20px;
}

.download-button {
  width: 100%;
  padding: 10px;
  background-color: #4a90e2;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  transition: background-color 0.2s;
}

.download-button:hover:not(.disabled) {
  background-color: #357abd;
}

.download-button.disabled {
  background-color: #d9d9d9;
  cursor: not-allowed;
}

@media (max-width: 600px) {
  .image-processor-card {
    margin: 10px;
    padding: 15px;
  }

  .upload-label {
    padding: 15px;
  }
}

/* Ideogram */

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, sans-serif;
}

.title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 24px;
  color: #333;
}

.error-message {
  background-color: #fee2e2;
  border: 1px solid #ef4444;
  color: #991b1b;
  padding: 12px;
  border-radius: 6px;
  margin-bottom: 16px;
}

.input-section {
  margin-bottom: 24px;
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.bulk-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 16px;
  margin-bottom: 16px;
  resize: vertical;
  min-height: 150px;
  font-family: inherit;
  box-sizing: border-box;
}

.bulk-input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

.prompts-container {
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 24px;
}

.prompt-card {
  background: white;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.prompt-text {
  margin-bottom: 16px;
  line-height: 1.5;
  background-color: #e3e3e3;
  border: 1px solid #e5e7eb;
  border-radius: 8px;
}

.content-text {
  border-radius: 8px;
  border: 1px solid #e5e7eb;
}

.button {
  background-color: #2563eb;
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.button:hover {
  background-color: #1d4ed8;
}

.button:disabled {
  background-color: transparent;
  color: #2563eb;
  border: 1px solid #2563eb;
  cursor: not-allowed;
  pointer-events: none;
}

.button-outline {
  background-color: transparent;
  border: 1px solid #2563eb;
  color: #2563eb;
}

.button-outline:hover {
  background-color: #2563eb;
  color: white;
}

.button-outline:disabled:hover {
  background-color: transparent;
  color: #9ca3af;
}

.button-group {
  display: flex;
  gap: 8px;
  margin-top: 12px;
}

.image-container {
  margin-top: 16px;
}

.generated-image {
  width: 100%;
  border-radius: 6px;
  margin-bottom: 12px;
}

.actions-container {
  display: flex;
  gap: 12px;
  margin-top: 24px;
  justify-content: center;
}

.loading-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  padding: 24px;
}

.loading-spinner {
  width: 32px;
  height: 32px;
  border: 3px solid #e5e7eb;
  border-top-color: #2563eb;
  border-radius: 50%;
  animation: spin 1s linear infinite;
}

.normal-input {
  width: 100%;
  padding: 12px;
  border: 1px solid #d1d5db;
  border-radius: 6px;
  font-size: 16px;
  margin-bottom: 16px;
  resize: vertical;
  font-family: inherit;
}

.normal-input:focus {
  outline: none;
  border-color: #2563eb;
  box-shadow: 0 0 0 2px rgba(37, 99, 235, 0.1);
}

.d-flex {
  display: flex;
}

.ml-2 {
  margin-left: 8px;
}
.mr-2 {
  margin-right: 8px;
}

.justify-content-center {
  justify-content: center;
}

.del-button{
  margin-left: 5px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 640px) {
  .container {
    padding: 16px;
  }

  .button-group {
    flex-direction: column;
  }

  .actions-container {
    flex-direction: column;
  }
}
