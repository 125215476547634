/* login.css */

*,
*:before,
*:after {
  box-sizing: border-box;
}

/* Container styles */
.login-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f9fafb;
  padding: 3rem 1rem;
}

.login-box {
  max-width: 28rem;
  width: 100%;
  background: white;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.login-box > * + * {
  margin-top: 2rem;
}

/* Header styles */
.login-header {
  margin-top: 1.5rem;
}

.login-title {
  text-align: center;
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 800;
  color: #111827;
}

/* Form styles */
.login-form {
  margin-top: 2rem;
  width: 100%;
}

.login-form > * + * {
  margin-top: 1.5rem;
}

/* Error message styles */
.login-error-alert {
  background-color: #fee2e2;
  border: 1px solid #f87171;
  color: #b91c1c;
  padding: 0.75rem 1rem;
  border-radius: 0.375rem;
  position: relative;
  margin-bottom: 1rem;
}

.login-error-message {
  display: block;
}

/* Input container styles */
.input-container {
  width: 100%;
  border-radius: 0.375rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
}

.input-container > div {
  width: 100%;
}

.input-container > div:first-child input {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.input-container > div:last-child input {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px; /* Remove double border */
}

/* Input field styles */
.input-field {
  display: block;
  width: 100%;
  padding: 0.75rem 1rem;
  border: 1px solid #d1d5db;
  color: #111827;
  font-size: 0.875rem;
  line-height: 1.25rem;
  background-color: white;
}

.input-field::placeholder {
  color: #6b7280;
}

.input-field:focus {
  outline: none;
  z-index: 1; /* Bring focused input to front */
  position: relative;
  border-color: #6366f1;
  box-shadow: 0 0 0 1px #6366f1;
}

/* Specific input styles */
.input-field[type="text"] {
  border-top-left-radius: 0.375rem;
  border-top-right-radius: 0.375rem;
}

.input-field[type="password"] {
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
}

/* Hidden labels */
.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/* Button container */
.button-container {
  width: 100%;
  margin-top: 1.5rem;
}

/* Button styles */
.submit-button {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  padding: 0.75rem 1rem;
  border: 1px solid transparent;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-radius: 0.375rem;
  color: white;
  background-color: #4f46e5;
  cursor: pointer;
  transition: background-color 0.2s;
}

.submit-button:hover {
  background-color: #4338ca;
}

.submit-button:focus {
  outline: none;
  box-shadow: 0 0 0 2px #fff, 0 0 0 4px #4f46e5;
}

/* Responsive adjustments */
@media (min-width: 640px) {
  .login-container {
    padding: 3rem 1.5rem;
  }

  .login-error-message {
    display: inline;
  }
}

@media (min-width: 1024px) {
  .login-container {
    padding: 3rem 2rem;
  }
}
