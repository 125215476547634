.App {
  text-align: center;
  max-width: 70%;
  margin: auto;
}

.toolbar {
  margin-bottom: 10px;
}

.toolbar button {
  margin-right: 5px;
  padding: 5px 10px;
}

.editor-container {
  border: 1px solid #ccc;
  min-height: 200px;
  padding: 10px;
  margin-bottom: 20px;
  cursor: text;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 20px;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  z-index: 1000;
}

.popup button {
  margin: 5px;
}

.link-suggestion-block > div{
 width: 250px;
}

.link-suggestion-block > div > input{
  width: 95%;
 }
 
.sticky-header{
  margin-bottom: 20px;
  position: sticky;
  top: 0px;
  padding: 12px;
  z-index: 100;
  background-color: #f5f3f3;
  border: 1px solid rgb(206, 212, 218);
}

.word-count {
  margin-left: 20px;
  font-weight: bold;
  color: #555;
}

.suggestion-popup{
  background-color: #ebebeb;
  color: black;
  padding: 10px;
  width: 320px;
  border-radius: 4px;
  border: 1px solid rgb(206, 212, 218);
  position: absolute;
  font-size: 16px;
  font-weight: 400;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}

.instruction-section{
  font-size: 14px;
  justify-content: center;
  display:flex;
}

.instruction-section > div{
  margin-right: 5px;
  padding: 5px;
}

.city-remover-input{
  height: 34px;
  margin: 5px;
  width: 211px;
  border-radius: 0.5rem;
  border: 1px solid #000;
}

.DraftEditor-root {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.public-DraftStyleDefault-block {
  margin-bottom: 1em;
}

.mt-4 {
  margin-top: 1.25rem;
}