.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 8px;
  width: 80%;
  max-width: 800px;
  max-height: 90vh;
  overflow-y: auto;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.modal-header h2 {
  margin: 0;
}

.close-button {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 0;
  color: #666;
}

.close-button:hover {
  color: #000;
}

.editor-wrapper {
  border: 1px solid #ccc;
  border-radius: 4px;
  max-height: 70vh;
  overflow-y: auto;
  padding: 1rem;
}

.button-container {
  display: flex;
  gap: 10px;
}

.clean-button,
.copy-button {
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.2s;
}

.clean-button {
  background-color: #2563eb;
  color: white;
}

.clean-button:hover {
  background-color: #1d4ed8;
}

.copy-button {
  background-color: #2563eb;
  color: white;
}

.copy-button:hover {
  background-color: #1d4ed8;
}

/* Draft.js editor customization */
.DraftEditor-root {
  height: 100%;
}

.public-DraftEditorPlaceholder-root {
  color: #999;
}

.public-DraftEditor-content {
  min-height: 180px;
}

.sticky-button-container {
  position: sticky;
  top: 0;
  z-index: 10;
  background-color: white;
  padding: 1rem;
  border-bottom: 1px solid #eee;
}

.flex {
  display: flex;
}
.justtify-between {
  justify-content: space-between;
}
